// ES6 Polyfill
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import featuresTabs from './modules/web/featuresTabs';
import header from './modules/web/header';
import animateCounters from './modules/web/countAnimation';
import animationObserver from './modules/web/animationObserver';
import animatedText from './modules/web/animatedText';
import arrowRotation from './modules/web/arrowRotation';
import distanceText from './modules/web/distanceText';
import blogHeader from './modules/web/blogHeader';

window.addEventListener('load', featuresTabs.init);
window.addEventListener('load', header.init);
window.addEventListener('load', animateCounters);
window.addEventListener('load', animationObserver);
window.addEventListener('load', animatedText);
window.addEventListener('load', arrowRotation);
window.addEventListener('load', distanceText);
window.addEventListener('load', blogHeader);
