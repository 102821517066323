export default function blogHeader() {
    const stickyThreshold = 50;
    const transitionDuration = 500;
    const hysteresisFactor = 0.5;

    const header = document.querySelector('.js-blog-header');
    if (!header) {
        return;
    }

    let isSticky = false;
    let isTransitioning = false;
    let timeoutId = null;
    let ticking = false;

    function setHeaderState(shouldBeSticky) {
        if (shouldBeSticky === isSticky || isTransitioning) {
            return;
        }

        isTransitioning = true;
        isSticky = shouldBeSticky;

        header.classList.toggle('sticky', isSticky);

        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            isTransitioning = false;
            timeoutId = null;
        }, transitionDuration);
    }

    function checkScrollPosition() {
        if (isTransitioning) {
            return;
        }

        const currentScrollY = window.scrollY;
        const unstickThreshold = stickyThreshold * hysteresisFactor;

        if (!isSticky && currentScrollY > stickyThreshold) {
            setHeaderState(true);
        } else if (isSticky && currentScrollY < unstickThreshold) {
            setHeaderState(false);
        }
    }

    function onScroll() {
        if (!ticking) {
            window.requestAnimationFrame(() => {
                checkScrollPosition();
                ticking = false;
            });
            ticking = true;
        }
    }

    window.addEventListener('scroll', onScroll, {passive: true});

    checkScrollPosition();
}
